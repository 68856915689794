import Murmur from '@icij/murmur'
import Rails from "@rails/ujs"
import Vue from'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'

import '@/vendor/jquery'
import '@/vendor/underscore'

import { CollapsePlugin, FormInputPlugin, ModalPlugin, VBTogglePlugin } from 'bootstrap-vue'

import ActiveAnchors from '@/shared/active_anchors.vue'
import DonateBanner from '@/shared/donate_banner.vue'
import DonateTimebomb from '@/shared/donate_timebomb.vue'
import CollapsableDisclaimer from '@/shared/collapsable_disclaimer.vue'
import OpenapiDefinitionCard from '@/shared/openapi_definition_card.vue'
import OpenapiEndpoints from '@/shared/openapi_endpoints.vue'
import OpenapiDefinitionList from '@/shared/openapi_definition_list.vue'
import OpenapiDefinitionProperties from '@/shared/openapi_definition_properties.vue'
import OpenapiDefinition from '@/shared/openapi_definition.vue'
import PowerPlayers from '@/shared/power_players.vue'
import SearchFilterLinks from '@/shared/search_filter_links.vue'
import SearchFormControl from '@/shared/search_form_control.vue'
import TermsModal from '@/shared/terms_modal.vue'
import TipsForm from '@/tips/form.vue'

import '@/utils/graph'
import '@/utils/ujs'
import '@/utils/vue_context'

// Global styles
import '@/application.scss'

Vue.use(CollapsePlugin)
Vue.use(FormInputPlugin)
Vue.use(ModalPlugin)
Vue.use(VBTogglePlugin)
Vue.use(VueI18n)
Vue.use(Murmur)
Vue.use(Vuex)

Vue.component('ActiveAnchors', ActiveAnchors)
Vue.component('DonateBanner', DonateBanner)
Vue.component('DonateTimebomb', DonateTimebomb)
Vue.component('CollapsableDisclaimer', CollapsableDisclaimer)
Vue.component('OpenapiDefinitionCard', OpenapiDefinitionCard)
Vue.component('OpenapiEndpoints', OpenapiEndpoints)
Vue.component('OpenapiDefinitionProperties', OpenapiDefinitionProperties)
Vue.component('OpenapiDefinitionList', OpenapiDefinitionList)
Vue.component('OpenapiDefinition', OpenapiDefinition)
Vue.component('SearchFilterLinks', SearchFilterLinks)
Vue.component('PowerPlayers', PowerPlayers)
Vue.component('SearchFormControl', SearchFormControl)
Vue.component('TermsModal', TermsModal)
Vue.component('TipsForm', TipsForm)

// Ensure Rails UJS wasn't loaded yet
if (!window._rails_loaded) {
  Rails.start()
}