<script>
import { kebabCase } from 'lodash'
export default {
  name: 'OpenapiDefinitionCard',
  props: {
    name: {
      type: String,
      required: true
    },
    definition: {
      type: Object,
      required: true
    }
  },
  computed: {
    keys() {
      return this.name.split('::').map(kebabCase).slice(0, 2)
    },
    label() {      
      const [schema, name] = this.keys
      const key = `schema.${schema}.models.${name}.label`
      return this.$te(key) ? this.$t(key) : name
    },
    model() {
      return this.name.split('::').map(kebabCase).join('/')
    },
    href() {
      return `/schema/${this.model}`
    },
    description() {
      const [schema, model] = this.keys
      const key = `schema.${schema}.models.${model}.description`
      return this.$te(key) ? this.$t(key) : null
    }
  }
}
</script>

<template>
  <div class="openapi-definition-card bg-light p-3">
    <h5>
      <a>{{ label }}</a>
    </h5>
    <p v-if="description">
      {{ description }}
    </p>
    <div class="d-flex">
      <a class="btn btn-primary btn-sm" :href="href">
        View
      </a>
    </div>
  </div>  
</template>