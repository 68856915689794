<script>
import { kebabCase } from 'lodash'

export default {
  name: 'OpenapiDefinitionPropertyType',
  props: {
    name: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      required: true
    },
    property: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 0
    }
  },
  computed: {
    isRef () {
      return !!this.property?.$ref
    },
    ref() {
      return this.property?.$ref?.split('::').pop()
    },
    hasItems() {
      return this.property.type === 'array' && !!this.property?.items
    },
    href() {
      return `/schema/${this.schema}/${kebabCase(this.ref)}`
    },
    classList() {
      return {
        'openapi-definition-property-type--nested': this.level > 0
      }
    }
  }
}
</script>

<template>
  <span class="openapi-definition-property-type" :class="classList">
    <template v-if="hasItems">
      <span>Array:</span>
      <openapi-definition-property-type :name="name" :schema="schema" :property="property.items" :level="level + 1" />
    </template>
    <template v-else>
      <template v-if="isRef">
        <a :href="href">{{ ref }}</a>
      </template>
      <template v-else>
        {{ property?.type?.toLowerCase() }}
      </template>
    </template>
  </span>
</template>

<style lang="scss" scoped>
@import '../utils/variables.scss';

.openapi-definition-property-type {
  font-family: monospace;
  font-size: 0.875rem;
  white-space: nowrap;
  color: $code-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(&--nested) {
    background: $light;
    padding: 0 $spacer-xxs;
  }

  & > a {
    color: inherit;
    text-decoration: underline;
  }
}
</style>