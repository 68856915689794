export function isEmbedded() {
  try {
      return window.self !== window.top || hasEmbedQueryParam()
  } catch (_) {
      return hasEmbedQueryParam()
  }
}

export function hasEmbedQueryParam() {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  // Get the value of the 'embed' parameter
  const embedValue = params.get('embed')
  // Check if 'embed' parameter is truthy (e.g., '1', 'true', or other truthy values)
  return embedValue && ['1', 'true', 't', 'yes'].indexOf(embedValue.toLowerCase()) > -1
}