<script>
import { get, kebabCase, keys, mapValues, pickBy, values } from 'lodash'

export default {
  name: 'OpenapiEndpoints',
  props: {
    src: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'oldb'
    },
    model: {
      type: String
    }
  },
  data() {
    return {
      spec: null
    }
  },
  async created() {
    this.spec = await fetch(this.src).then(r => r.json())
  },
  methods: {
    withRenderer(path) {
      const url = new URL(`${this.spec.basePath}${path}`, document.location)
      // Add the current schema
      url.searchParams.set('renderer', this.schema)
      return decodeURI(url.href)
    },
    methodClassList(method) {
      return {
        'text-info': method === 'get',
        'text-success': method === 'post',
        'text-warning': method === 'put',
        'text-danger': method === 'delete'
      }
    }
  },
  computed: {
    name() {
      return [this.schema, this.model].filter(Boolean).join('::')
    },
    all() {
      return get(this.spec, 'paths', {})
    },
    paths() {
      return mapValues(this.all, (methods) => {
        return pickBy(methods, (operation) => {
          const name = kebabCase(this.name)
          return !this.model || get(operation, 'x-representers', []).map(kebabCase).includes(name)
        })
      })
    }
  }
}
</script>

<template>
  <div class="openapi-endpoints">
    <div v-for="(methods, path) in paths" :key="path">
      <div v-for="(operation, method) in methods" :key="method" class="input-group openapi-endpoints__item mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text font-weight-bold openapi-endpoints__item__method" :class="methodClassList(method)">
            {{ method.toUpperCase() }}
          </span>
        </div>
        <input type="text" class="form-control openapi-endpoints__item__url" readonly :value="withRenderer(path)" />
        <span class="input-group-append openapi-endpoints__item__copy">
          <haptic-copy class="btn btn-light" :text="withRenderer(path)" hide-label />
        </span>
      </div>
    </div>
  </div>  
</template>

<style lang="scss" scoped>
@import '../utils/variables.scss';

.openapi-endpoints {
  &__item {
    &__method {
      font-family: $font-family-monospace;
    }

    &__url.form-control, &__copy > .btn {
      background-color: $input-bg;
      border: $input-border-width solid $input-border-color;
    }

    &__url {
      border-right: 0;
    }

    &__copy > .btn {
      border-left: 0;
    }
  }
}
</style>