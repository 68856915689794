<script>
import { get, find, kebabCase } from 'lodash'

import OpenapiDefinitionProperties from './openapi_definition_properties.vue'

export default {
  name: 'OpenapiDefintion',
  components: {
    OpenapiDefinitionProperties
  },
  props: {
    src: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'oldb'
    },
    model: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      spec: null
    }
  },
  async created() {
    this.spec = await fetch(this.src).then(r => r.json())
  },
  computed: {
    definitions() {
      return get(this.spec, 'definitions', {})
    },
    definition() {
      return find(this.definitions, (value, key) => {
        return key.split('::').map(kebabCase).join('::').startsWith(`${this.schema}::${this.model}`)
      })
    }
  }
}
</script>

<template>
<div class="openapi-definition">
  <openapi-definition-properties v-if="definition" :definition="definition" :schema="schema" />
</div>
</template>