import Vue from 'vue'
import VueI18n from 'vue-i18n'
import $ from 'jquery'

$(() => {
  document.querySelectorAll('.vue-context').forEach(el => {
    new Vue({
      el,
      i18n: new VueI18n({ 
        locale: 'en', 
        messages: i18n
      }),
      data() {
        return {
          breakpointMatches: {
            'xs': false,
            'sm': false,
            'md': false,
            'lg': false,
            'xl': false,
            'xxl': false,
          }
        }
      },
      mounted() {
        this.setBreakpointMatches()
      },
      methods: {
        isBreakpoint(breakpoint) {
          return this.breakpointMatches[breakpoint]
        },
        getBreakpointMinWidth(breakpoint) {
          return getComputedStyle(document.body).getPropertyValue(`--breakpoint-${breakpoint}`)
        },
        doesBreakpointMatch(breakpoint) {
          const minWidth = this.getBreakpointMinWidth(breakpoint)
          return window.matchMedia(`(min-width: ${minWidth})`).matches
        },
        setBreakpointMatches() {
          for (const breakpoint in this.breakpointMatches) {
            this.breakpointMatches[breakpoint] = this.doesBreakpointMatch(breakpoint)
          }
        }
      }
    })
  })
});
