import LK from './linkurious'
import $ from 'jquery'

window.loadGraph = function(){
  var $container = $('.graph-container__workspace__canvas');

  if($container.length && $container.is(':visible')){
    $container.html('');
    window.lk = LK($container);
    window.lk.widget(document.body.nodeData);

    $(document).on('click', '[data-close-tooltip]', function(e){
      e.preventDefault();
      window.lk.closeTooltip();
    });

    $(document).on('click', '[data-node-zoom-in]', function(e){
      e.preventDefault();
      window.lk.zoomIn();
    });

    $(document).on('click', '[data-node-zoom-out]', function(e){
      e.preventDefault();
      window.lk.zoomOut();
    });

    $(document).on('click', '[data-node-undo]', function(e){
      e.preventDefault();
      window.lk.undoLastExpand();
    });

    $(document).on('click', '[data-node-fullscreen]', function(e){
      e.preventDefault();
      window.lk.fullscreen();
    });
  }
}

$(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e){
  $('.graph-container__workspace__canvas').toggleClass('graph-container__workspace__canvas--fullscreen');
});

$(document).on('click', '[data-fullscreen]', function(e){
  e.preventDefault();
  // Find the element to display in fullscreen
  var selector = $(this).data('fullscreen');
  var elem = $(selector)[0];
  // Find the right method to request fullscreen mode
  elem.requestFullscreen = elem.requestFullscreen || elem.mozRequestFullScreen || elem.webkitRequestFullscreen || $.noop;
  // Request fullscreen mode for thie element
  elem.requestFullscreen();
});

$(document).ready(function (){
  loadGraph();
});

// Load the graph after the back button is clicked
document.addEventListener("page:restore", function() {
  loadGraph();
});
