<script>
import { cloneDeep } from 'lodash'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'TipsForm',
  components: { 
    VueRecaptcha
  },
  props: {
    tip: {
      type: Object,
      default: () => ({ })
    },
    recaptchaSiteKey: {
      type: String
    }
  },
  data() {
    return {
      form: cloneDeep(this.tip)
    }
  },
  computed: {
    referrer () {
      return this.form.referrer || document.referrer
    }
  }
}
</script>

<template>
  <form class="tips-form" method="POST" action="/tips">
    <slot name="header"></slot>

    <div class="form-group">
      <label>Tell us your name if you can:</label>
      <input type="text" name="tip[submitter_name]" v-model="form.submitter_name" class="form-control" />
    </div>

    <div class="form-group">
      <label>Your email address if you want us to reply:</label>
      <input type="email" name="tip[reply_email]" v-model="form.reply_email" class="form-control" />
    </div>

    <div class="form-group">
      <label>Are you ?</label>
      <select name="tip[submitter_category]" v-model="form.submitter_category" class="custom-select">
        <option value="">–</option>
        <option>A journalist</option>
        <option>A researcher, a programmer, etc.</option>
        <option>An individual that appears in the database</option>
        <option>A concerned citizen with a tip</option>
        <option>Other</option>
      </select>
    </div>

    <div class="form-group">
      <label>What is your request about?</label>
      <select name="tip[subject]" v-model="form.subject" class="custom-select">
        <option value="">–</option>
        <option>I have a tip for ICIJ</option>
        <option>I have general questions about the database</option>
        <option>I have a comment about my entry in the database</option>
        <option>Other</option>
      </select>
      <div v-if="form.subject === 'I have general questions about the database'" class="alert alert-warning mt-3">
          You can check our FAQ <a href="https://offshoreleaks.icij.org/pages/about">here</a>.
      </div>
    </div>

    <div class="form-group">
      <label>The data you are interested in is part of:</label>
      <select name="tip[investigation]" v-model="form.investigation" class="custom-select">
        <option value="">–</option>
        <option>Offshore Leaks</option>
        <option>Panama Papers</option>
        <option>Bahamas Leaks</option>
        <option>Paradise Papers</option>
        <option>Pandora Papers</option>
        <option>I’m not sure</option>
      </select>
      <div v-if="form.investigation === 'Offshore Leaks'" class="alert alert-warning mt-3">
        The Offshore Leaks data is current through 2010.
      </div>
      <div v-else-if="form.investigation === 'Panama Papers'" class="alert alert-warning mt-3">
        The Panama Papers data is current through 2015.
      </div>
      <div v-else-if="form.investigation === 'Bahamas Leaks'" class="alert alert-warning mt-3">
        The Bahamas Leaks data is current through early 2016.
      </div>
      <div v-else-if="form.investigation === 'Paradise Papers'" class="alert alert-warning mt-3">
        The Paradise Papers  data is current through 2014.
      </div>
    </div>

    <div class="form-group">
      <label>Please provide the URL of the page you are writing to us about: </label>
      <input type="text" name="tip[subject_url]" v-model="form.subject_url" class="form-control" />
    </div>

    <div class="form-group">
      <label>What is the name of the company you are writing about? </label>
      <input type="text" name="tip[company_name]" v-model="form.company_name" class="form-control" />
    </div>

    <div class="form-group">
      <label>Do you have any relationship with that company? If so, why?</label>
      <textarea name="tip[company_relationship]" v-model="form.company_relationship" rows="6" class="form-control"></textarea>
    </div>

    <div class="form-group">
      <label>Please leave here your comment about this company:</label>
      <textarea name="tip[company_comment]" v-model="form.company_comment" rows="6" class="form-control"></textarea>
    </div>


    <div class="form-group" v-if="recaptchaSiteKey">
      <vue-recaptcha :sitekey="recaptchaSiteKey" />
    </div>

    <input type="hidden" name="tip[referrer]" :value="referrer" />
    <button type="submit" class="btn btn-primary btn-lg text-uppercase font-weight-bold">
      Send
    </button>

    <slot name="footer"></slot>
  </form>
</template>