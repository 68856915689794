<script>
import { get, pickBy } from 'lodash'

export default {
  name: 'OpenapiDefintionList',
  props: {
    src: {
      type: String,
      required: true
    },
    schema: {
      type: String,
      default: 'oldb'
    }
  },
  data() {
    return { 
      spec: null
    }
  },
  async created () {
    this.spec = await fetch(this.src).then(r => r.json())
  },
  computed: {
    definitions() {
      return get(this.spec, 'definitions', {})  
    },
    schemaDefinitions() {
      return pickBy(this.definitions, (value, key) => {
        return key.toLowerCase().startsWith(`${this.schema}::`)
      })
    }
  }
}
</script>

<template>
  <div class="openapi-definitions">
    <div class="openapi-definitions__list row">
      <div class="openapi-definitions__list__item col-12 col-sm-6 col-md-4 col-lg-3 pb-5" v-for="(definition, name) in schemaDefinitions" :key="name">
        <openapi-definition-card
            class="h-100"
           :name="name" 
           :definition="definition" />
      </div>
    </div>
  </div>
</template>