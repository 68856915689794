<script>
export default {
  name: 'OpenapiDefinitionPropertyName',
  props: {
    name: {
      type: String,
      required: true
    },
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    inherit() {
      return this.property["x-inherit"]
    }
  }
}
</script>

<template>
  <span class="openapi-definition-property-name">
    <span class="text-muted">{{ inherit }}</span>:<span class="font-weight-bold">{{ name }}</span>
  </span>
</template>