<script>
import { property } from 'lodash'
import Fuse from 'fuse.js'

export default {
  name: 'SearchFilterLinks',
  props: {
    placeholder: {
      type: String,
      default: 'Filter...'
    },
    links: {
      type: Array,
      default: () => []
    },
    sticky: {
      type: Boolean
    }
  },
  data () {
    return {
      q: '',
      selectedIndex: -1
    }
  },
  computed: {
    filteredLinks () {
      if (this.q) {
        return this.fuse
          .search(this.q)
          .map(property('item'))
      }
      return this.links
    },
    selectedLinks () {
      return this.filteredLinks.map((link, index) => {
        const selected = index === this.selectedIndex
        return { ...link, selected }
      })
    },
    fuse () {
      return new Fuse(this.links, { 
        shouldSort: false, 
        keys: ['label'],
        threshold: 0.3 
      })
    }
  },
  methods: {
    selectNextLink() {
      this.selectedIndex = Math.min(this.selectedIndex + 1, this.filteredLinks.length - 1)
    },
    selectPreviousLink() {
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0)
    },
    selectFirstLink() {
      this.selectedIndex = Math.min(0, this.filteredLinks.length - 1)
    },
    selectLastLink() {
      this.selectedIndex = this.filteredLinks.length - 1
    },
    submit() {
      if (this.selectedIndex > -1) {
        window.location = this.filteredLinks[this.selectedIndex].href
      }
    }
  },
  watch: {
    q () {
      this.selectFirstLink()
    },
    async selectedIndex () {
      if (this.selectedIndex > -1) {
        // Wait for the new class to be applied
        await this.$nextTick()
        // Then scroll the element into view
        this.$el
          .querySelector('.filterable-links__list__item--selected')
          .scrollIntoView({ block: 'end' })
      }
    }
  }
 }
</script>

<template>
  <div class="filterable-links">
    <form 
      class="p-3 bg-light" 
      :class="{ 'position-sticky sticky-top': sticky }" 
      @submit.prevent="submit"
    >
      <b-form-input 
        autocomplete="off" 
        autofocus
        type="text" 
        v-model="q" 
        @keyup.up="selectPreviousLink"
        @keyup.down="selectNextLink"
        @keyup.page-up="selectFirstLink"
        @keyup.page-down="selectLastLink"
        :placeholder="placeholder" />
    </form>
    <ul v-if="selectedLinks.length" class="list-unstyled mb-0 filterable-links__list">
      <li v-for="{ active, selected, label, href } in selectedLinks"  
          class="border-bottom p-0 filterable-links__list__item" 
          :class="{ 
            'filterable-links__list__item--active': active,
            'filterable-links__list__item--selected': selected
          }"
          :key="href">
        <a :href="href" class="px-3 py-2 d-block">
          {{ label }}
        </a>
      </li>
    </ul>
    <div v-else class="p-3 text-center">
      No matches for "{{ q }}"
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '../utils/variables.scss';

  .filterable-links {
    &__list {
      &__item {

        &--active {
          font-weight: bold;
    
          a {
            color: inherit;
          }
        }

        &--selected {
          background: $component-active-bg;
          color: $component-active-color;

          a {
            color: inherit;
          }
        }
      }
    }
  }
</style>