<script>
import Cookies from 'js-cookie'
import { isEmbedded } from '@/utils/iframe'

export default {
  name: 'TermsModal',
  data () {
    return {
      accept: false
    }
  },
  mounted () {
    if (this.mustOpenModal) {
      this.openModal()
    }
  },
  computed: {
    mustOpenModal () {
      return !Cookies.get('terms.accept') && !isEmbedded()
    }
  },
  methods: {
    openModal () {
      this.$refs.modalTerms.show()
    },
    submit () {
      this.$refs.modalTerms.hide()
      Cookies.set('terms.accept', 1, { expires: 60, sameSite: 'strict' })
    }
  }
}
</script>

<template>
  <b-modal 
    centered 
    class="terms_modal"
    content-class="p-sm-5 p-2"
    body-class="pt-0"
    hide-footer 
    hide-header-close
    header-class="pb-0 border-bottom-0"
    lazy
    no-close-on-backdrop
    no-close-on-esc
    ref="modalTerms"
    size='lg'>
    <template #modal-title>
      <h2>Please read the statement below before searching</h2>
    </template>
    <p>There are legitimate uses for offshore companies and trusts. The inclusion of a person or entity in the ICIJ Offshore Leaks Database is not intended to suggest or imply that they have engaged in illegal or improper conduct. Many people and entities have the same or similar names. We suggest you confirm the identities of any individuals or entities included in the database based on addresses or other identifiable information. The data comes directly from the leaked files ICIJ has received in connection with various investigations and each dataset encompasses a defined time period specified in the database. Some information may have changed over time. <a href="/tips/new" class="font-weight-bold">Please contact us</a> if you find an error in the database.</p>
    <form @submit.prevent="submit">
      <div class="d-flex flex-column flex-sm-row">
        <div class="card py-3 py-sm-0 px-3 bg-light flex-grow-1 d-flex flex-column justify-content-center">
          <input type="hidden" name="accept" value="0"/>
          <label for="accept" class="m-0">
            <input type="checkbox" v-model="accept" name="accept" id="accept" />
            <span title="Accept terms">
              I have read and understood the terms
            </span>
          </label>
        </div>
        <div class="flex-auto my-3 my-sm-0 ml-sm-3">
          <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="!accept">
            Submit
          </button>
        </div>
      </div>
      <p class="insist alert alert-warning mt-4" style="display: none">
        Please accept the terms to continue
      </p>
    </form>
  </b-modal>
</template>