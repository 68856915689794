<script>
import Cookies from 'js-cookie'
import { isEmbedded  } from '@/utils/iframe'

export default {
  name: 'DonateTimebomb',
  created() {
    if (this.shouldCountVisitedPages) {
      this.visitedPages++
    }
  },
  mounted() {
    if (this.shouldDisplayModal && !isEmbedded()) {
      this.open()
    }
  },
  methods: {
    open () {
      this.$refs.modalDonateTimebomb.show()
    },
    donate () {
      this.$refs.modalDonateTimebomb.hide()
    }
  },
  computed: {
    shouldDisplayModal() {
      // After the fourth visited page or every 8 pages
      return this.visitedPages === 4 || this.visitedPages > 4 && (this.visitedPages - 4) % 8 === 0
    },
    shouldCountVisitedPages () {
      // Only once the donate banner is closed
      return !!Cookies.get('donate.banner.hidden')
    },
    visitedPages: {
      cache: false,
      get() {
        return parseInt(Cookies.get('donate.timebomb.pages')) || 0
      },
      set(value) {
        // Page counter expires in 1 hour
        Cookies.set('donate.timebomb.pages', value, { expires: 1 / 24, sameSite: 'strict' })
      }
    }
  }
}
</script>

<template>
  <b-modal 
    lazy 
    size='xl'
    hide-footer
    header-class="position-absolute border-0 donate-timebomb-modal-header"
    body-class="p-0"
    content-class="bg-transparent p-0"
    ref="modalDonateTimebomb">
    <donate-banner 
      class="p-0" 
      show 
      hide-header-close />
  </b-modal>
</template>

<style lang="scss">
  .donate-timebomb-modal-header {
    z-index: 1010;
    width: 100%;
  }
</style>