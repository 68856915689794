<script>
import { get } from 'lodash'
import OpenapiDefinitionPropertyName from './openapi_definition_property_name.vue'
import OpenapiDefinitionPropertyType from './openapi_definition_property_type.vue'

export default {
  name: 'OpenapiDefintionProperties',
  components: {
    OpenapiDefinitionPropertyName,
    OpenapiDefinitionPropertyType
  },
  props: {
    definition: {
      type: Object,
      required: true
    },
    schema: {
      type: String,
      required: true
    },
    level: {
      type: Number,
      default: 0
    },
    small: {
      type: Boolean,
    },
    noHeader: {
      type: Boolean,
    }
  },
  computed: {
    classList() {
      return {
        'small': this.small,
        'openapi-definition-properties--nested': this.level > 0,
        'openapi-definition-properties--even-nested': this.level % 2 !== 0,
      }
    },
    properties() {
      return get(this.definition, 'properties', {})
    }
  },
  methods: {
    hasNestedProperties(property) {
      return property.type === 'object' && property.properties
    },
    showType(property) {
      return property.type !== 'object'
    }
  }
}
</script>

<template>
<div class="openapi-definition-properties" :class="classList">
  <template v-if="!noHeader">
    <div class="openapi-definition-properties__header row no-gutters py-1 font-weight-bold">
      <div class="openapi-definition-properties__header__col openapi-definition-properties__header__col--name col">
        Name
      </div>
      <div class="openapi-definition-properties__header__col openapi-definition-properties__header__col--label col">
        Label
      </div>
      <div class="openapi-definition-properties__header__col openapi-definition-properties__header__col--type col">
        Type
      </div>
    </div>
  </template>
  <div class="openapi-definition-properties__body">
    <div v-for="(property, name) in properties" :key="name" class="openapi-definition-properties__body__item row no-gutters py-2">
      <div class="openapi-definition-properties__body__item__col openapi-definition-properties__body__item__col--name col">
        <openapi-definition-property-name :name="name" :property="property" />
        <div v-if="property.description" class="small text-muted">
          {{ property.description }}
        </div>
      </div>
      <div class="openapi-definition-properties__body__item__col openapi-definition-properties__body__item__col--label col">
        {{ property["x-label"] }}
      </div>
      <div class="openapi-definition-properties__body__item__col openapi-definition-properties__body__item__col--type col">
        <openapi-definition-property-type :name="name" :property="property" :schema="schema" />
      </div>
      <template v-if="hasNestedProperties(property)">
        <div class="openapi-definition-properties__body__item__nested col-12 py-2 mt-2">
          <openapi-definition-properties
            no-header 
            small
            :definition="property" 
            :schema="schema" 
            :level="level + 1" />
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<style lang="scss" scoped>
  @import '../utils/variables.scss';

  .openapi-definition-properties {

    &__header__col, &__body__item__col {
      
      &--label.col {
        padding-left: $grid-gutter-width * 0.5;
        flex: 0 0 10vw;
        max-width: 10rem;
        min-width: 6rem;
      }
      
      &--type.col {
        padding-left: $grid-gutter-width * 0.5;flex: 0 0 15vw;
        max-width: 15rem;
        min-width: 6rem;
      }
    }

    &__header {
      border-bottom: 2px solid $body-color;
    }

    &__body {
      &__item {

        &:not(:last-of-type) {
          border-bottom: 1px solid rgba($body-color, 0.2);
        }

        &__nested {
          
          $tree-bar-width: 2px;
          $tree-bar-color: rgba($primary, 0.2);
          $tree-bar-top: 1.1rem;
          $tree-bar-left: -1.7rem;
          $tree-bar-offset-left: 0.7rem;
          $tree-bar-offset-top: 0.9rem;

          margin-left: $tree-bar-offset-left - $tree-bar-left;
          max-width: calc(100% + $tree-bar-left - $tree-bar-offset-left);
          display: relative;

          .openapi-definition-properties__body__item {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: $tree-bar-top; 
              left: calc($tree-bar-left + $tree-bar-width);
              right: calc(100% + #{$tree-bar-top * 0.5});
              height: $tree-bar-width;
              background-color: $tree-bar-color;
            }

            &:after {
              content: '';
              position: absolute;
              top: 0;
              left: $tree-bar-left;
              width: $tree-bar-width;
              height: calc(100% + 1px); // 1px is the width of the item's border-bottom 
              background-color: $tree-bar-color;
            }

            &:first-of-type {
              // The tree bar should be a little higher to the top
              &:after {
                top: -$tree-bar-offset-top;
                height: calc(100% + 1px + $tree-bar-offset-top); // 1px is the width of the item's border-bottom 
              }
            }

            &:last-of-type {
              // The tree bar should be a little wider to the left
              &:before  {
                left: $tree-bar-left;
              } 

              // The tree bar should not continue until the next item
              &:after {
                height: $tree-bar-top;
              }
            }

            &:first-of-type:last-of-type {              
              &:after {
                height: calc($tree-bar-top + $tree-bar-offset-top);
              }
            }
          }

        }
      }
    }
  }
</style>