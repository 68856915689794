<script>
export default {
  name: 'SearchFormControl',
  props: {
    action: {
      type: String,
      default: '/search'
    },
    defaultQ: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search the full Offshore Leaks database'
    },
    submitButtonClass: {
      type: String,
      default: 'btn btn-primary text-uppercase px-3 px-md-5'
    },
    validate: {
      type: Boolean
    }
  },
  data () {
    return {
      q: this.defaultQ
    }
  },
  computed: {
    valid () {
      return !!this.q
    }
  }
}
</script>

<template>
  <form class="search-form-control input-group" method="get" :action="action" :validate="validate">
    <slot name="header"></slot>
    <input 
      class="search-form-control__field form-control" 
      name="q" 
      maxlength="50"
      type="text" 
      v-model="q" 
      :placeholder="placeholder" />
    <div class="input-group-append">
      <button type="submit" :class="submitButtonClass" :disabled="!valid">
        Search
      </button>
    </div>
    <slot name="footer"></slot>
  </form>
</template>