<script>
import Cookies from 'js-cookie'

export default {
  name: 'CollapsableDisclaimer',
  data()  {
    return {
      collapsed: false
    }
  },
  mounted() {
    if (this.mustCollapse) {
      this.toggle(null, true)
    }
  },
  computed: {
    mustCollapse() {
      return !!Number(Cookies.get('disclaimer.collapsed'))
    }
  },
  methods: {
    toggle(_, value = null) {
      this.collapsed = value ?? !this.collapsed
      Cookies.set('disclaimer.collapsed', Number(this.collapsed), { expires: 60, sameSite: 'strict' })
    }
  }
}
</script>

<template>
<div>
  <div class="d-none d-lg-block">
      <slot name="toggler" v-bind="{ toggle, collapsed }">
        <button class="btn btn-light" @click="toggle">
          Toggle
        </button>
      </slot>
      <slot v-if="collapsed" name="reduced" />
      <slot v-else />
    </div>
    <div class="d-lg-none">
      <slot name="reduced" />
    </div>
  </div>
</template>