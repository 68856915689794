<script>
import Cookies from 'js-cookie'
import { isEmbedded } from '@/utils/iframe'

export default {
  name: 'DonateBanner',
  props: {
    show: {
      type: Boolean,
      default: null
    },
    hideHeaderClose: {
      type: Boolean
    },
    cookie: {
      type: String,
      default: 'donate.banner.hidden'
    }
  },
  data() {
    return {
      showBanner: false
    }
  },
  created  () {
    this.showBanner = this.show !== null ? this.show : !Cookies.get(this.cookie)
  },
  computed: {
    shouldDisplayBanner() {
      return this.showBanner && !isEmbedded()
    },
  },
  methods: {
    hide () {
      Cookies.set(this.cookie, 1, { expires: 7, sameSite: 'strict' })
      this.showBanner = false
    },
    openDonateFormModal () {
      this.$refs.formModal.show()
      /**
       * @event donate
       */
      this.$emit('donate')
    }
  }
}
</script>

<template>
  <div class="donate-banner" v-if="shouldDisplayBanner">
    <a @click.prevent="hide" class="donate-banner__hide" v-if="!hideHeaderClose">
      <span class="sr-only">Hide</span>
      <i class="fa fa-times"></i>
    </a>
    <div class="donate-banner__content">
      <div class="row no-gutters">
        <div class="col-12 col-lg px-5 d-flex flex-column justify-content-end">
          <div>
            <h2 class="donate-banner__content__heading text-uppercase mt-5">
              <span class="donate-banner__content__heading__piece donate-banner__content__heading__piece--0">
                This data
              </span>
              <span class="donate-banner__content__heading__piece donate-banner__content__heading__piece--1">
                should be 
              </span>
              <span class="donate-banner__content__heading__piece donate-banner__content__heading__piece--2">
                public
              </span>
            </h2>
            <p class="donate-banner__content__deck mb-4">
              We need your support to keep it that way.
            </p>
          </div>
        </div>
        <div class="col text-center donate-banner__content__people d-none d-lg-block"></div>
        <div class="col px-5 d-flex flex-column justify-content-end">
          <div>
            <div class="mb-4">
              <a class="btn btn-primary btn-lg text-uppercase font-weight-bold px-5 py-1" href="https://www.icij.org/donate/" target="_blank">
                Donate
              </a>
            </div>
            <p class="donate-banner__content__deck mb-4 d-none d-lg-block">
              Make a donation to keep it free, open and accessible.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../utils/variables.scss';

  .donate-banner {
    display: flex;
    position: relative;
    
    &__hide {
      position: absolute;
      cursor: pointer;
      right: $spacer;
      top: $spacer;
      color: $primary;
      z-index: 600;
    }
    
    &__content {
      background: #f1f1f1 url('@/assets/images/bg_banner.png') no-repeat center center; 
      background-size: cover;
      width: 100%;
      position: relative;
      z-index: 500;

      &__heading {
        color:#2A2A2A;

        &__piece {
          display: block;
        }
        
        &__piece--0 {
          font-size: 27px;
          opacity: 0.7;
          line-height: 25px;
        }
        
        &__piece--1 {
          font-size: 37px;
          opacity: 0.8;
          line-height: 30px;
        }
        
        &__piece--2 {
          font-size: 57px;
          line-height: 50px;
        }
      }

      &__deck {
        color: #5C5C5C;
        font-size: 21px;
        line-height: 24px;
        font-weight: 700;
      }

      &__people {
        background: url('@/assets/images/banner_people.png') center bottom no-repeat;
        background-size: contain;
      }
    }
  }
</style>