<script>
import { filter, sampleSize } from 'lodash'

export default {
  name: 'PowerPlayers',
  props: {
    release: {
      type: String,
      default: 'pandora-papers'
    },
    hideTitle: {
      type: Boolean
    },
    reduced: {
      type: Boolean
    }
  },
  data () {
    return { 
      selectedRelease: this.release,
      players: []
    }
  },
  async created () {
    const response = await fetch('/power-players.json')
    this.players = await response.json()
  },
  computed: {
    releaseLabels () {
      return {
        'pandora-papers': 'Pandora Papers',
        'paradise-papers': 'Paradise Papers',
        'panama-papers': 'Panama Papers'
      }
    },
    releases () {
      return Object.keys(this.releaseLabels)
    },
    activeReleaseLabel () {
      return this.releaseLabels[this.selectedRelease]
    },
    sampleSize () {
      return this.reduced ? 4 : 6
    },
    samplePlayers () {
      return sampleSize(this.releasePlayers, this.sampleSize)
    },
    releasePlayers () {
      const release = this.selectedRelease 
      return filter(this.players, { release })
    },
    playerClassList () {
      return {
        'power-players__item': true,
        'col-6': true,
        'col-xl-4': !this.reduced,
        'col-lg-3': this.reduced
      }
    }
  },
  methods: {
    is (release) {
      return this.selectedRelease === release
    },
    label (release) {
      return this.releaseLabels[release]
    },
    select (release) {
      this.selectedRelease = release
    }
  }
}
</script>

<template>
  <div class="mb-5">
    <template v-if="!hideTitle">
      <template v-if="reduced">
        <h3 class="h6 text-dark mb-4 text-uppercase">
          More power players from <span class="text-muted">{{ label(selectedRelease) }}</span>
        </h3>
      </template>
      <template v-else>
        <h3 class="h5 text-muted mb-4">
          Explore offshore connections
        </h3>
      </template>
    </template>
    <div class="bg-light p-3">
      <div v-if="reduced" class="row pb-3">
        <div class="col-lg-7 col-12">
          <ul class="list-unstyled list-group list-group-horizontal">
            <li class="mx-auto mx-lg-0 mr-lg-2" v-for="release in releases" :key="release">
              <a @click.prevent="select(release)" class="btn text-uppercase font-weight-bold h6 text-nowrap" :class="{ 'btn-primary': is(release)}">
                {{ label(release) }}
              </a>
            </li>
          </ul>
        </div>
        <div class="d-none d-lg-block col text-center text-lg-right">
          <a href="/power-players" class="text-uppercase h6 btn btn-dark font-weight-bold text-wrap">
            Explore all
          </a>
        </div>
      </div>

      <div class="row no-gutters">
        <div v-if="!reduced" class="col-12 col-xl-4 text-center">
          <h3 class="h2 text-uppercase mt-5">
            The <br class="d-none d-xl-block" />power <br class="d-none d-xl-block" />players
          </h3>
          <p class="mb-5">
            Explore the offshore connections of world leaders, politicians and their relatives and associates.
          </p>
          <ul class="list-unstyled mx-4 row">
            <li class="col mb-3" v-for="release in releases" :key="release">
              <a @click.prevent="select(release)" class="btn btn-lg btn-block" :class="{ 'btn-primary': is(release) }">
                {{ label(release) }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col text-center">
          <div class="row text-center power-players">
            <a :href="player.link" :class="playerClassList" v-for="player in samplePlayers" :key="player.slug">
              <figure class="power-players__item__mugshot mb-2">
                <img :src="player.image" />
              </figure>
              <div class="power-players__item__subtitle text-uppercase">
                {{ player.subtitle || player.nodes.first.name }}
              </div>
              <p class="power-players__item__title small text-muted mb-3">
                {{ player.title }}
              </p>
            </a>
          </div>			
          <a v-if="!reduced" href="/power-players" class="text-uppercase h5 btn btn-dark font-weight-bold mt-4">
            Explore all
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '../utils/variables.scss';

  .power-players {
    margin: 0 auto;

    &__item {
      box-sizing: border-box;
      text-decoration: none;
      color: $body-color;
      
      @include media-breakpoint-down (md) {
        &:nth-child(n+5):nth-child(-n+7) {
          display: none;
        }
      }    
      
      @include media-breakpoint-down (sm) {
        &:nth-child(n+3):nth-child(-n+6) {
          display: none;
        }
      }

      &:hover {
        text-decoration: none;
      }

      &__subtitle {
        font-weight: 900;
      }

      &__mugshot {
        margin:auto;
        height: 140px;
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-top:1em;

        img {
          max-width: 220px;
          max-height: 100%;
          height: auto;
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }
    }
  }
</style>