<script>
export default {
  name: 'ActiveAnchors', 
  props: {
    activeClass: {
      type: String,
      default: 'active'
    },
    rootMargin: {
      type: String,
      default: '0px'
    }
  },
  data() {
    return {
      mounted: false,
      direction: 'up',
      prevYPosition: 0
    }
  },
  async mounted() {
    this.mounted = !!await this.$nextTick()
    this.ids.forEach((id) => {
      this.observer.observe(document.getElementById(id))
    })
  },
  computed: {
    links() {
      if (!this.mounted) {
        return []
      }
      return [...this.$el.querySelectorAll('a[href^="#"]')]
    },
    ids() {
      return this.links.map((link) => {
        return link.getAttribute('href').split('#').pop()
      })
    },
    observer() {
      return new IntersectionObserver(this.intersection, {
        root: this.scrollRoot,
        rootMargin: this.rootMargin,
        threshold: 0
      })
    },
    scrollRoot() {
      return document
    }
  },
  methods: {
    intersection(entries, observer) {
      entries.forEach((entry) => {
        this.setScrollDirection()
        if (this.isTarget(entry)) {
          const target = this.getTargetSection(entry.target)
          const id =  target?.getAttribute('id')
          this.deactvateAll()
          this.activate(id)
        }
      })
    },
    deactvateAll() {
      this.links.forEach((link) => {
        link.classList.remove(this.activeClass)
      })
    },
    activate(id) {
      this.links.forEach((link) => {
        if (link.getAttribute('href').split('#').pop() === id) {
          link.classList.add(this.activeClass)
        }
      })
    },
    setScrollDirection() {
      this.direction = this.scrollRoot.scrollTop > this.prevYPosition ? 'down' : 'up'
      this.prevYPosition = this.scrollRoot.scrollTop
    },
    getTargetSection(target) {
      if (this.direction === 'up') {
        return target
      }
      
      return target.nextElementSibling ?? target
    },
    isTarget(entry) {
      if (this.direction === 'down' && !entry.isIntersecting) {
        return true
      }

      if (this.direction === 'up' && entry.isIntersecting) {
        return true
      }

      return false
    }
  }
}
</script>

<template>
  <div><slot /></div>
</template>