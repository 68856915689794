import Mustache from 'mustache'
import $ from 'jquery'
import _ from 'underscore'

export default function LK($container) {
  var LK = Object.create(null);
  var nodesPath = [];
  var $spinner = $('#spinner');
  var $undoButton = $('[data-node-undo]');

  var sigmaInstance,
    activeStatePlugin,
    designPlugin,
    dragNodesListener,
    legendPlugin,
    selectPlugin,
    tooltipsPlugin,
    locatePlugin,
    sigmaContainer,
    initialized = false,
    zoomDuration = 500;

  LK.content = {
    "styles": {
      "nodes": {
        "color": {
          "by": "category",
          "scheme": "nodes.qualitative.categories",
          "active": true
        },
        "icon": {
          "by": "category",
          "scheme": "nodes.icons.categories",
          "active": true
        }
      },
      "edges": {
        "color": {
          "by": "edgeType",
          "scheme": "edges.qualitative.type",
          "active": true
        }
      }
    },
    "palette": {
      "nodes": {
        "icons": {
          "categories": {
            "Officer": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uF007"
            },
            "Address": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uF015"
            },
            "Company": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uF1ad"
            },
            "Entity": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uf1ad"
            },
            "Intermediary": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uF19c"
            },
            "Other": {
              "font": "FontAwesome",
              "scale": 1,
              "color": "#fff",
              "content": "\uf10c"
            }
          }
        },
        "qualitative": {
          "categories": {
            "Company": "#420e00",
            "Officer": "#be4400",
            "Address": "#937c6f",
            "Entity": "#3c1405",
            "Intermediary": "#851d00",
            "Other": "#a17600",
          },
        }
      },
      "edges": {
        "qualitative": {
          "type": {
            "officer_of": "#d48a60",
            "intermediary_of": "#8a6960",
            "registered_address": "#baaba3",
            "similar": "#d48a60",
            "is shareholder of": "#d8b299"
          },
        },
      }
    }
  };

  var settings = {
    /**
     * POWEREDBY SETTINGS:
     * *******************
     */
    poweredByHTML: '<a href="https://linkurio.us/" target="_blank">Linkurious</a> and <a href="https://neo4j.com/" target="_blank">Neo4j</a>',
    poweredByURL: null,

    /**
     * RENDERERS SETTINGS:
     * *******************
     */
    defaultEdgeType: 'tapered',

    // Labels:
    font: 'Roboto',
    defaultLabelColor: '#000',
    defaultLabelSize: 11,
    labelThreshold: 5,
    labelAlignment: 'center',
    defaultEdgeLabelSize: 11,
    edgeLabelThreshold: 4,
    labelHoverShadow: '',
    edgeLabelHoverShadow: '',
    maxNodeLabelLineLength: 35,

    // Nodes:
    defaultNodeColor: '#999999',
    nodeBorderColor: 'default',
    // Hovered nodes:
    hoverFontStyle: 'bold',
    nodeHoverBorderSize: 2,
    defaultNodeHoverBorderColor: '#ffffff',
    // Active nodes:
    nodeActiveColor: 'node',
    defaultNodeActiveColor: '#999999',
    nodeActiveLevel: 3,
    nodeActiveBorderSize: 2,
    nodeActiveOuterBorderSize: 4,
    defaultNodeActiveBorderColor: '#ffffff',
    defaultNodeActiveOuterBorderColor: '#f65565',
    nodeHoverLevel: 1,

    // Edges:
    edgeColor: 'default',
    defaultEdgeColor: '#a9a9a9',
    // Hovered edges:
    edgeHoverExtremities: true,
    edgeHoverLevel: 1,
    // Actve edges:
    activeFontStyle: 'bold',
    edgeActiveColor: 'default',
    defaultEdgeActiveColor: '#f65565',
    edgeActiveLevel: 3,

    // Halo:
    nodeHaloSize: 25,
    edgeHaloSize: 20,
    nodeHaloColor: '#ffffff',
    edgeHaloColor: '#ffffff',
    nodeHaloStroke: true,
    nodeHaloStrokeColor: '#a9a9a9',
    nodeHaloStrokeWidth: 0.5,

    // Node images:
    imgCrossOrigin: 'anonymous',

    // Legend:
    legendBorderWidth: 0.5,
    legendBorderRadius: 4,
    legendBorderColor: '#999999',

    /**
     * RESCALE SETTINGS:
     * *****************
     */
    minNodeSize: 5,
    maxNodeSize: 5,
    minEdgeSize: 2,
    maxEdgeSize: 2,

    /**
     * CAPTORS SETTINGS:
     * *****************
     */
    zoomingRatio: 1.382,
    doubleClickZoomingRatio: 1.7,
    zoomMin: 0.1,
    zoomMax: 10,
    doubleClickZoomDuration: 200,

    /**
     * GLOBAL SETTINGS:
     * ****************
     */
    autoRescale: ['nodeSize', 'edgeSize'],
    doubleClickEnabled: true,
    enableEdgeHovering: true,
    edgeHoverPrecision: 10,
    approximateLabelWidth: true,
    mouseWheelEnabled: false,

    /**
     * CAMERA SETTINGS:
     * ****************
     */
    nodesPowRatio: 0.8,
    edgesPowRatio: 0.8,

    /**
     * ANIMATIONS SETTINGS:
     * ********************
     */
    animationsTime: 0,

    // Legend:
    legendBorderWidth: 0.5,
    legendBorderRadius: 4,
    legendBorderColor: '#999999',

    // Glyphs
    glyphScale: 0.3,
    glyphTextColor: 'black',
    glyphStrokeColor: 'black',
    glyphLineWidth: 4,
    glyphFontStyle: 'normal',
    glyphFontScale: 1,
    glyphFont: 'Helvetica',
    glyphTextThreshold: 6,
    glyphStrokeIfText: true,
    glyphThreshold: 1,
    drawGlyphs: true
  };

  var tooltipsSettings = {
    node: {
      show: 'clickNode',
      hide: 'clickStage',
      cssClass: 'sigma-tooltip',
      position: 'top',
      autoadjust: true
    },
    edge: {
      show: 'clickEdge',
      hide: 'clickStage',
      cssClass: 'sigma-tooltip',
      position: 'top',
      autoadjust: true
    }
  };

  var node_coordinates = {};
  var multi = 1;

  var renderHalo = function() {

    var nodes = [],
        edges = [];

    activeStatePlugin.nodes().forEach(function(node) {
      nodes = nodes.concat(sigmaInstance.graph.adjacentNodes(node.id));
      edges = edges.concat(sigmaInstance.graph.adjacentEdges(node.id, {withHidden: false}));
    });
    nodes.concat(activeStatePlugin.nodes());

    activeStatePlugin.edges().forEach(function(edge) {
      nodes.push(sigmaInstance.graph.nodes(edge.source));
      nodes.push(sigmaInstance.graph.nodes(edge.target));
    });

    // TODO remove duplicates from nodes
    sigmaInstance.renderers[0].halo({
      nodes: nodes,
      edges: edges
    });
  };

  var gatherNodesAndEdges = function(nodes) {
    var graph = { nodes: [], edges: [] };
    var edgeIds = sigmaInstance.graph.edges().map(n => n.id);
    var existingNodeIds = sigmaInstance.graph.nodes().map(n => n.id);
    var nodeIds = existingNodeIds.concat(nodes.map(node => node.linkurious_id));

    nodes.forEach(function(node) {
      var numberOfConnections = 0;
      var numberOfHiddenConnections = -(node.edges || []).length;

      _.get(node, 'data.statistics.digest'.split('.'), []).forEach(function(o) {
        numberOfConnections += o.edges;
        numberOfHiddenConnections += o.edges;
      });

      var category = _.get(node, 'data.categories.0'.split('.'), null);
      var color = LK.content.palette.nodes.qualitative.categories[category];
      var properties = _.get(node, 'data.properties'.split('.'), {})
      
      graph.nodes.push({
        id: node.linkurious_id,
        node_id: node.id,
        active: node.id == $container.data('node-id'),
        label: properties.name || properties.address,
        data: node.data,
        category: category,
        edge_count: (node.edges || []).length,
        x: Math.random() * 50,
        y: Math.random() * 50,
        numberOfConnections: numberOfConnections,
        numberOfHiddenConnections: numberOfHiddenConnections,
        glyphs: [{
          position: 'top-left',
          fillColor: '#fff',
          textColor: color,
          strokeColor: color,
          strokeIfText: false,
          content: numberOfHiddenConnections,
          draw: (nodesPath.indexOf(node.id) === -1 && numberOfHiddenConnections > 0)
        }]
      });

      // Add edges without duplicates
      (node.edges || [])
        .filter(function (edge) {
          const hasEdge = edgeIds.includes(edge.id);
          const hasSource = nodeIds.includes(edge.source);
          const hasTarget = nodeIds.includes(edge.target);
          return !hasEdge && hasSource && hasTarget;
        })
        .forEach(function (edge) {
          edgeIds.push(edge.id);
          graph.edges.push(edge);
        });
    });

    return graph;
  }

  var updateViz = function(nodeData, clearGraph) {
    $spinner.hide();

    if(nodesPath.length > 1) {
      $undoButton.show();
    } else {
      $undoButton.hide();
    }

    if (clearGraph) {
      sigmaInstance.graph.clear();
    }

    var graph = gatherNodesAndEdges(nodeData);

    graph.nodes.forEach(function(node) {
      if (node.id && !sigmaInstance.graph.nodes(node.id)) {
        sigmaInstance.graph.addNode(node);
      }
    });

    graph.edges.forEach(function(edge) {
      if(edge.edgeType === undefined){
        edge.edgeType = edge.type;
        edge.label = edge.data.properties.link;
        edge.type = settings.defaultEdgeType;
      }
      if (!sigmaInstance.graph.edges(edge.id)) {
        sigmaInstance.graph.addEdge(edge);
      }
    });

    // Auto-curve updates the edges types from defaulEdgeType
    // to curvedArrow
    sigma.canvas.edges.autoCurve(sigmaInstance);
    // Instantiate the PoweredBy plugin:
    sigmaInstance.renderers[0].poweredBy();
    sigmaInstance.refresh();

    LK.startLayout();

    if (LK.content && LK.content.styles && LK.content.palette) {
      LK.setDesign(LK.content.styles, LK.content.palette);
    }
  }

  /**
   * Initialize le widget. It should be called only once.
   */
  LK.widget = function(nodeData) {
    if(initialized) return;

    initialized = true;
    sigmaContainer = $container[0]

    sigmaInstance = new sigma({
      renderer: {
        container: sigmaContainer,
        type: 'canvas'
      },
      settings: settings
    });

    sigmaInstance.graph.clear();

    // Load initial nodes into nodesPath
    nodesPath.push($container.data('node-id'));

    // ------------------------------------------------------------------------------------------
    //                                  LOAD GRAPH
    // ------------------------------------------------------------------------------------------

    function getExpandedNode(nodeId) {
      // Avoid expending a node several time
      if(nodesPath.indexOf(nodeId) > -1) return;

      var confirmed = true;
      var limit = sigmaInstance.graph.nodes().length;
      // In some cases, we limit only load the 10 first connections
      var first_count = 10

      sigmaInstance.graph.nodes().forEach(function(n, i, nodes){
        if(n.node_id === nodeId){
          // Calculate the maximum number of node to load
          limit = n.numberOfHiddenConnections + n.edge_count
          // If the limit is bigger than the number of nodes + 10
          if(limit > nodes.length + first_count) {
            limit = n.edge_count + first_count
            confirmed = window.confirm("You are going to expand a node with " + n.numberOfHiddenConnections + " connections, but only the first " + first_count + " will be shown. Are you sure? (You can explore the connections on the node page.)");
          // If there is more than 50 connections to display
          } else if (n.numberOfHiddenConnections > 50) {
            confirmed = window.confirm("You are going to expand a node with " + n.numberOfHiddenConnections + " connections. Are you sure? (You can explore the connections on the node page.)");
          }
        }
      });

      if(!confirmed){
        return false;
      }

      nodesPath.push(nodeId);

      sigmaInstance.graph.nodes().forEach(function(n){
        if(nodesPath.indexOf(n.node_id) !== -1){
          n.glyphs[0]['draw'] = false
        }
      });

      var url = '/nodes/' + nodeId + '.json';

      $spinner.show();
      $.ajax(url, {
        headers: {
          Accept: "application/json, text/javascript"
        },
        data: {
          limit: limit
        },
        // Before update the viz, we must remove any extra edges
        complete: function(xhr, status) {
          // Collect every edge ids for the current viz
          var existing_edges_ids = _.map(sigmaInstance.graph.edges(), 'id')
          // Find the selected node (or get an empty object)
          var selected_node = _.find(xhr.responseJSON, {id: nodeId}) || {}
          // Collect every new edge ids
          var selected_node_edges_ids = _.map(selected_node['edges'] || [], 'id')
          // Count the number of new edges
          var count_new_ids = _.reduce(selected_node_edges_ids, function(total, id) {
            return total + (existing_edges_ids.indexOf(id) === -1)
          }, 0);
          // Does the API returned more edges that it should?
          if (limit >= first_count && count_new_ids >= first_count) {
              // Only keep the new edges
              selected_node['edges'] = _.filter(selected_node['edges'], function(e) {
                return existing_edges_ids.indexOf(e.id) === -1
              })
              // Remove extra edges
              selected_node['edges'] = selected_node['edges'].slice(0, first_count)
              // New nodes ids (from new edges)
              var new_nodes_ids = _.chain(selected_node['edges']).map(function(e) {
                return _.chain(e).pick('source', 'target').values().value()
              }).flatten().uniq().value()
              // And extra nodes
              xhr.responseJSON = _.filter(xhr.responseJSON, function(node) {
                return new_nodes_ids.indexOf(node.linkurious_id) > -1
              });
          }
          updateViz(xhr.responseJSON, false)
        }
      })
    }

    function saveCoordinates() {
      sigmaInstance.graph.nodes().forEach(function(node) {
        node_coordinates[node.id] = [node.x, node.y]
      });
    }

    // ------------------------------------------------------------------------------------------
    //                                  SIGMA PLUGINS
    // ------------------------------------------------------------------------------------------

    // Glyph
    sigmaInstance.renderers[0].glyphs();

    sigmaInstance.renderers[0].bind('render', function() {
      renderHalo();
      sigmaInstance.renderers[0].glyphs();
    });


    designPlugin = sigma.plugins.design(sigmaInstance);

    legendPlugin = sigma.plugins.legend(sigmaInstance);
    legendPlugin.setVisibility(false);

    activeStatePlugin = sigma.plugins.activeState(sigmaInstance);
    dragNodesListener = new sigma.plugins.dragNodes(sigmaInstance, sigmaInstance.renderers[0], activeStatePlugin);
    selectPlugin = sigma.plugins.select(sigmaInstance, activeStatePlugin, sigmaInstance.renderers[0]);

    // Instantiate the Locate plugin:
    locatePlugin = sigma.plugins.locate(sigmaInstance, {
      animation: {
        node: {
          duration: zoomDuration
        },
        edge: {
          duration: zoomDuration
        },
        center: {
          duration: zoomDuration
        }
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      focusOut: true,
      zoomDef: 0.3
    });

    sigmaInstance.renderers[0].bind('render', function() {
      renderHalo();
    });

    //ACTIVE EVENTS
    activeStatePlugin.bind('activeNodes', function () {
      renderHalo();
    });
    activeStatePlugin.bind('activeEdges', function () {
      renderHalo();
    });

    // Instantiate the tooltips plugin with a Mustache renderer
    var tooltipsNodeTmpl = LK.dom.$('tooltip-node').innerHTML;
    Mustache.parse(tooltipsNodeTmpl);   // optional, speeds up future uses
    tooltipsSettings.node.template = tooltipsNodeTmpl;
    tooltipsSettings.node.renderer = function(node, template) {
      node = mustachPrepare(node, 'node');
      return Mustache.render(template, node);
    };

    var tooltipsEdgeTmpl = LK.dom.$('tooltip-edge').innerHTML;
    Mustache.parse(tooltipsEdgeTmpl);   // optional, speeds up future uses
    tooltipsSettings.edge.template = tooltipsEdgeTmpl;
    tooltipsSettings.edge.renderer = function(edge, template) {
      edge = mustachPrepare(edge, 'edge');
      return Mustache.render(template, edge);
    };

    tooltipsPlugin = sigma.plugins.tooltips(sigmaInstance, sigmaInstance.renderers[0], tooltipsSettings);

    sigmaInstance.bind('hovers', function (event) {
      if(event.data.enter.nodes.length) {
        // Add the 'hover' class to the DOM container:
        if (-1 == sigmaContainer.className.indexOf('hoverNodes')) {
          sigmaContainer.className += ' hoverNodes';
        }
      }
      else if(event.data.enter.edges.length) {
        // Add the 'hover' class to the DOM container:
        if (-1 == sigmaContainer.className.indexOf('hoverEdges')) {
          sigmaContainer.className += ' hoverEdges';
        }
      }
      else if(event.data.leave.nodes.length || event.data.leave.edges.length) {
        // Remove the 'hover' class from the DOM container:
        sigmaContainer.className = sigmaContainer.className.replace(' hoverNodes', '');
        // Remove the 'hover' class from the DOM container:
        sigmaContainer.className = sigmaContainer.className.replace(' hoverEdges', '');
      }
    });

    sigmaInstance.bind('doubleClickNode', function(e){
      multi = e.data.node.x;
      saveCoordinates();
      getExpandedNode(e.data.node.node_id);
    });

    // ------------------------------------------------------------------------------------------
    //                                  PUBLIC INTERFACE
    // ------------------------------------------------------------------------------------------

    // public access for iframe parent:
    LK.sigma = sigmaInstance;
    LK.plugins = {
      activeState: activeStatePlugin,
      dragNodesListener: dragNodesListener,
      design: designPlugin,
      locate: locatePlugin,
      select: selectPlugin,
      tooltips: tooltipsPlugin,
      legend: legendPlugin
    };

    if (LK.content) {
      if (LK.content.description) {
        var elt = LK.dom.all('meta[name=description]')[0];
        if (elt) {
          elt.parentNode.removeChild(elt);
        }
        elt = document.createElement('meta');
        elt.name = 'description';
        elt.content = LK.content.description;
        LK.dom.all('head')[0].appendChild(elt);
      }
    }

    LK.toggleLegend = function () {
      legendPlugin.setVisibility(!legendPlugin.visible);
    };

    LK.showLegend = function () {
      legendPlugin.setVisibility(true);
    };

    LK.hideLegend = function () {
      legendPlugin.setVisibility(false);
    };

    updateViz(nodeData, false);

    LK.updateUI();

    // In case the WebGL renderer is used, we must wait for FontAwesome to be loaded.
    // http://www.w3.org/TR/css-font-loading/
    if (document.fonts) {
      // document.fonts.ready() method is going to be replaced with
      // document.fonts.ready attribute in the future.
      var fontsReady = document.fonts.ready;
      if (typeof(fontsReady) == "function") {
        fontsReady = document.fonts.ready();
      }
      fontsReady.then(function() {
        LK.sigma.refresh({ skipIndexation:true });
        legendPlugin.draw();
      });
    }
    else { 
      // wait
      setTimeout(function() {
        LK.sigma.refresh({ skipIndexation:true });
        legendPlugin.draw();
      }, 2000);
    }

    return true;
  };

  /**
   * Manually open a tooltip on a node.
   * @param node
   */
  LK.openNodeTooltip = function(node) {
    var prefix = 'renderer1:';
    tooltipsPlugin.open(node, tooltipsSettings.node, node[prefix + 'x'], node[prefix + 'y']);
  };

  /**
   * Manually open a tooltip on an edge.
   * @param edge
   */
  LK.openEdgeTooltip = function(edge) {
    var prefix = 'renderer1:',
      source = sigmaInstance.graph.nodes(edge.source),
      target = sigmaInstance.graph.nodes(edge.target),
      x = (source[prefix + 'x'] + target[prefix + 'x']) * 0.5,
      y = (source[prefix + 'y'] + target[prefix + 'y']) * 0.5;
    tooltipsPlugin.open(edge, tooltipsSettings.edge, x, y);
  };

  /**
   * Close the current tooltip.
   */
  LK.closeTooltip = function() {
    tooltipsPlugin.close();
  };




  // ------------------------------------------------------------------------------------------
  //                                  LAYOUT FUNCTIONS
  // ------------------------------------------------------------------------------------------

  /**
   * Start/stop the ForceLink layout.
   */
  LK.startLayout = function() {
    
    var nodesCount = sigmaInstance.graph.nodes().length,
      scalingRatio = 2,
      gravity = (nodesCount > 250) ? (2 * nodesCount) / 100 : 2.5;

    // TODO replace by a more qualitative layout on graphs < 50 nodes
    if (nodesCount > 50) {
      scalingRatio = 2.5;
    } else if (nodesCount > 10) {
      scalingRatio = 5;
    } else if (nodesCount > 3) {
      scalingRatio = 10;
    } else if (nodesCount > 2) {
      scalingRatio = 30;
    } else {
      scalingRatio = 50;
    }

    if (!nodesCount) return;

    sigma.layouts.configForceLink(sigmaInstance, {
      worker: true,
      autoStop: true,
      background: true,
      maxIterations: 200,
      avgDistanceThreshold: 0.00001,
      linLogMode: true,
      barnesHutOptimize: (nodesCount > 1000),
      easing: 'cubicInOut',
      scalingRatio: scalingRatio,
      gravity: gravity,
      randomize: 'locally',
      randomizeFactor: 0.1,
      alignNodeSiblings: (nodesCount > 3),
      nodeSiblingsScale: 8,
      nodeSiblingsAngleMin: 0.3
    })
    
    var fa = sigma.layouts.startForceLink();

    fa.bind('stop', function(event) {
      locatePlugin.center();
    });
  };


  // ------------------------------------------------------------------------------------------
  //                                  DESIGN FUNCTIONS
  // ------------------------------------------------------------------------------------------

  /**
   * Clear current design, load new styles and palette, and apply.
   * @param styles
   * @param palette
   */
  LK.setDesign = function(styles, palette) {
    designPlugin.clear();
    designPlugin.setPalette(palette);
    designPlugin.setStyles(styles);

    if (styles.nodes && styles.nodes.icon) {
      sigmaInstance.settings('labelAlignment', 'right');
    }
    else {
      sigmaInstance.settings('labelAlignment', '');
    }

    designPlugin.apply();
    legendPlugin.draw();
  };


  // ------------------------------------------------------------------------------------------
  //                                  CAMERA FUNCTIONS
  // ------------------------------------------------------------------------------------------

  /**
   * Zoom on the specified node.
   * @param id The node id.
   * @param options
   */
  LK.locateNode = function(id, options) {
    var o = options || {};
    locatePlugin.nodes(id, options);
  };

  /**
   * Zoom on the specified edge.
   * @param id The edge id.
   * @param options
   */
  LK.locateEdge = function(id, options) {
    locatePlugin.edges(id, options);
  };

  LK.zoomOut = function() {
    sigma.utils.zoomTo(
      sigmaInstance.camera,
      0,
      0,
      sigmaInstance.settings('zoomingRatio'),
      { duration: 300 }
    );
  };

  LK.zoomIn = function() {
    sigma.utils.zoomTo(
      sigmaInstance.camera,
      0,
      0,
      1 / sigmaInstance.settings('zoomingRatio'),
      { duration: 300 }
    );
  };

  /**
   * Reset the camera zoom and position.
   */
  LK.zoomCenter = function() {
    sigma.utils.zoomTo(
      sigmaInstance.camera,
      0,
      0,
      1,
      { duration: 300 }
    );
  };

  /**
   * Display the graph in full screen mode.
   */
  LK.fullscreen = function() {
    sigma.plugins.fullScreen({ container: $container[0] });
  };

  LK.nodesPath = function(){
    return nodesPath;
  };

  LK.undoLastExpand = function(){
    if(nodesPath.length > 1) {
      nodesPath.pop();

      var url = '/nodes/collection/' + nodesPath.join(',') + '.json';

      $spinner.show();
      $.ajax(url, {
        headers: {Accept : "application/json, text/javascript"},
        complete: function(xhr, status) {
          updateViz(xhr.responseJSON, true)
        }
      });
    }
  };

  LK.updateUI = function() {
    legendPlugin.setVisibility(true);
  };

  // ------------------------------------------------------------------------------------------
  //                                  DOM UTILITY FUNCTIONS
  // ------------------------------------------------------------------------------------------

  LK.dom = {
    /**
     * Get the DOM element by id.
     * @param {string} id
     * @returns {Element}
     */
    $: function (id) {
      return document.getElementById(id);
    },

    /**
     * Get the DOM element by selector.
     * @param {string} selector
     * @returns {Element}
     */
    select: function(selector) {
      return document.querySelector(selector);
    },

    /**
     * Get a set of DOM elements by CSS selectors.
     * @param {string} selectors The CSS selectors.
     * @returns {NodeList}
     */
    all: function (selectors) {
      return document.querySelectorAll(selectors);
    },

    /**
     * Remove the specified CSS class from the specified DOM elements.
     * @param {string} selectors The CSS selectors.
     * @param {string} cssClass
     */
    removeClass: function(selectors, cssClass) {
      var nodes = document.querySelectorAll(selectors);
      var l = nodes.length;
      for (var i = 0 ; i < l; i++ ) {
        var el = nodes[i];
        // Bootstrap compatibility
        el.className = el.className.replace(cssClass, '');
      }
    },

    /**
     * Add the specified CSS class to the specified DOM elements.
     * @param {string} selectors The CSS selectors.
     * @param {string} cssClass
     */
    addClass: function (selectors, cssClass) {
      var nodes = document.querySelectorAll(selectors);
      var l = nodes.length;
      for (var i = 0 ; i < l; i++ ) {
        var el = nodes[i];
        // Bootstrap compatibility
        if (-1 == el.className.indexOf(cssClass)) {
          el.className += ' ' + cssClass;
        }
      }
    },

    /**
     * Remove the CSS class "hidden" from the specified DOM elements.
     * @param {string} selectors The CSS selectors.
     */
    show: function (selectors) {
      this.removeClass(selectors, 'hidden');
    },

    /**
     * Add the CSS class "hidden" to the specified DOM elements.
     * @param {string} selectors The CSS selectors.
     */
    hide: function (selectors) {
      this.addClass(selectors, 'hidden');
    },

    /**
     * Toggle the visibility of the specified DOM elements.
     * @param {string} selectors The CSS selectors.
     * @param {string} [cssClass] the optional CSS class. Default: "hidden"
     */
    toggle: function (selectors, cssClass) {
      var cssClass = cssClass || "hidden";
      var nodes = document.querySelectorAll(selectors);
      var l = nodes.length;
      for (var i = 0 ; i < l; i++ ) {
        var el = nodes[i];
        //el.style.display = (el.style.display != 'none' ? 'none' : '' );
        // Bootstrap compatibility
        if (-1 !== el.className.indexOf(cssClass)) {
          el.className = el.className.replace(cssClass, '');
        } else {
          el.className += ' ' + cssClass;
        }
      }
    }
  };

  // ------------------------------------------------------------------------------------------
  //                                  PRIVATE FUNCTIONS
  // ------------------------------------------------------------------------------------------

  /**
   * Format the node or edge data for Mustache.
   * @param item
   * @param type 'node' or 'edge'
   * @return     The modified item.
   */
  function mustachPrepare(item, type) {
    // see http://stackoverflow.com/a/9058774/
    item.mustacheProperties = [];
    item.mustacheCategories = [];

    var propertiesStructure = {
      'Officer':      {'name': 'Name','countries': 'Linked to','sourceID': 'Data from'},
      'Address':      {'address': 'Address','countries': 'Linked to','sourceID': 'Data from'},
      'Intermediary': {'name': 'Name','address': 'Address','status': 'Status',
                        'countries': 'Linked to','sourceID': 'Data from'},
      'Entity':       {'name': 'Name','jurisdiction_description': 'Jurisdiction',
                        'incorporation_date': 'Incorporation date','inactivation_date': 'Inactivation date',
                        'struck_off_date': 'Struck off date','status': 'Status', 'address': 'Address',
                        'countries': 'Linked to', 'sourceID': 'Data from', 'note': 'Note'},
      'Edge':         {'link': 'Role', 'start_date': 'From', 'end_date': 'To', 'sourceID': 'Data from'}
    }

    var urlPattern = /^((\\\\).+)|^((http|ftp|https):\/\/[\w-]+(\.[\w-]*)+)([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/,
      imagePattern = /\.(gif|jpe?g|tiff|png)$/i;

    if(type == 'edge'){
      item.category = 'Edge';
    }

    
    if(item.data && item.data.properties) {
      var propValue, propType;
      const properties = item.data.properties;

      for (var field_name in propertiesStructure[item.category]) {

        if (properties.hasOwnProperty(field_name) && properties[field_name] != "") {
          propValue = properties[field_name];
          propType = false;

          item.mustacheProperties.push({
            'key' : propertiesStructure[item.category][field_name],
            'value' : propValue,
            'text': !propType
          });
        }
      }

      if (type == 'node') {
        item.mustacheCategories.push(item.category);
      }
    }

    return item;
  }

  return LK;
}
